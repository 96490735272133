import { ResourcesConfig } from 'aws-amplify';

export interface FeatureFlags {
    disableRemoveOutlier: boolean;
    disableSmoothing: boolean;
    disableAveraging: boolean;
    disableRescale: boolean;
    disableAutoOptimize: boolean;
    disableColorCorrections: boolean;
}

export interface IConfig {
    environmentName: string;
    amplify: ResourcesConfig;
    featureFlags: FeatureFlags;
    userRegistrationScriptUrl: string;
    cmpGraphQLEndpoint: string;
    trackJs?: {
        token: string;
        application: string;
    };
    tracking: {
        domain: string;
        enabled: boolean;
        ignore: Array<string>;
    };
}

export const getConfig = (json: any): IConfig => ({
    environmentName: json.ENVIRONMENT,
    amplify: {
        Auth: {
            Cognito: {
                userPoolId: json.AMPLIFY_AUTH_USERPOOLID!,
                userPoolClientId: json.AMPLIFY_AUTH_USERPOOLWEBCLIENTID!,
                identityPoolId: json.AMPLIFY_AUTH_IDENTITYPOOLID!,
                loginWith: {
                    oauth: {
                        domain: json.AMPLIFY_OAUTH.DOMAIN,
                        scopes: json.AMPLIFY_OAUTH.SCOPE,
                        redirectSignIn: [json.AMPLIFY_OAUTH.REDIRECTSIGNIN],
                        redirectSignOut: [json.AMPLIFY_OAUTH.REDIRECTSIGNOUT],
                        responseType: json.AMPLIFY_OAUTH.RESPONSETYPE, // or 'token', note that REFRESH token will only be generated when the responseType is code
                    },
                },
            },
        },
        API: {
            GraphQL: {
                endpoint: json.APPSYNCH_GRAPHQLENDPOINT!,
                defaultAuthMode: json.APPSYNCH_AUTHENTICATIONTYPE!,
            },
        },
    },
    featureFlags: {
        disableRemoveOutlier: !!json.FEATURE_FLAGS.DISABLE_REMOVEOUTLIER,
        disableSmoothing: !!json.FEATURE_FLAGS.DISABLE_SMOOTHING,
        disableAveraging: !!json.FEATURE_FLAGS.DISABLE_AVERAGING,
        disableRescale: !!json.FEATURE_FLAGS.DISABLE_RESCALE,
        disableAutoOptimize: !!json.FEATURE_FLAGS.DISABLE_AUTOOPTIMIZE,
        disableColorCorrections: !!json.FEATURE_FLAGS.DISABLE_COLORCORRECTIONS,
    },
    userRegistrationScriptUrl: json.USERREGISTRATION_URL!,
    cmpGraphQLEndpoint: json.CMP_GRAPHQLENDPOINT,
    trackJs: json.TRACKJS,
    tracking: {
        domain: json.TRACKING.DOMAIN,
        enabled: json.TRACKING.ENABLED,
        ignore: json.TRACKING.IGNORE,
    },
});